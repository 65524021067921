import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import alertIcon from '../../assets/images/favicon-alert.png';
import alertSound from '../../assets/sounds/alert.mp3';

const TICK_DELAY = 1000; // not change it!

const LOW_IMPORTUNITY_DELAY = 1; // 1 second
const MID_IMPORTUNITY_DELAY = 600; // 10 minutes
const HIGH_IMPORTUNITY_DELAY = 1800; // 30 minutes

const originIcon = document.querySelector('link[rel="icon"]').href;

const isNotificationSupported = () => 'Notification' in window;

function useBrowserNotification() {
  const notificationIntervalId = useRef(null);
  const tickCounter = useRef(0);
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);
  const [audio] = useState(new Audio(alertSound));
  const [t] = useTranslation();

  if (isNotificationSupported()) {
    switch (Notification.permission) {
      case 'denied':
        break;
      case 'granted':
        break;
      case 'default':
        Notification.requestPermission();
        break;
      default:
        break;
    }
  }

  function tick() {
    if (tickCounter.current % LOW_IMPORTUNITY_DELAY === 0) {
      // change favicon
      const link = document.querySelector('link[rel="icon"]');
      if (link.href === originIcon) {
        link.setAttribute('href', alertIcon);
      } else {
        link.setAttribute('href', originIcon);
      }
    }
    if (tickCounter.current % MID_IMPORTUNITY_DELAY === 0) {
      // play sound
      audio.play();
    }
    if (tickCounter.current % HIGH_IMPORTUNITY_DELAY === 0) {
      // push
      if (isNotificationSupported() && Notification.permission === 'granted') {
        new Notification(t('common.youHaveChangesInTasks'));
      }
    }

    tickCounter.current += 1;
  }

  function enableBrowserNotification(message) {
    setIsNotificationEnabled(true);
  }

  function disableBrowserNotification() {
    setIsNotificationEnabled(false);
    tickCounter.current = 0;

    document.querySelector('link[rel="icon"]').setAttribute('href', originIcon);
  }

  function startNotifying() {
    notificationIntervalId.current = setInterval(tick, TICK_DELAY);
  }

  function stopNotifying() {
    clearInterval(notificationIntervalId.current);
    notificationIntervalId.current = null;
  }

  useEffect(() => {
    if (!notificationIntervalId.current && isNotificationEnabled) startNotifying();
    if (notificationIntervalId.current && !isNotificationEnabled) stopNotifying();
  }, [isNotificationEnabled]);

  return [enableBrowserNotification, disableBrowserNotification];
}

export default useBrowserNotification;
